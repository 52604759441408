/*
  Authors : initappz (Rahul Jograna)
  Website : https://initappz.com/
  App Name : Event Booking App Template
  This App Template Source code is licensed as per the
  terms found in the Website https://initappz.com/license
  Copyright and Good Faith Purchasers © 2021-present initappz.
*/
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {
  font-family: "regular";
  src: url(assets/fonts/OpenSans-Regular.ttf);
}

@font-face {
  font-family: "medium";
  src: url(assets/fonts/OpenSans-Medium.ttf);
}

@font-face {
  font-family: "bold";
  src: url(assets/fonts/OpenSans-Bold.ttf);
}

@font-face {
  font-family: "semibold";
  src: url(assets/fonts/OpenSans-SemiBold.ttf);
}

* {
  font-family: "regular";
}

.h1 {
  font-family: "regular";
  margin: 10px 0px;
  font-size: 25px;
  display: block;
}

.h5 {
  font-family: "regular";
  font-weight: bold;
  font-size: 20px;
  margin: 10px 0px;
  display: block;
}

.h6 {
  font-family: "regular";
  margin: 10px 0px;
  font-size: 13px;
  display: block;
  color: #ffffff;
}

.contentbtn {
  --border-radius: 100px;
}

ion-header {
  ion-toolbar {
    ion-title {
      font-size: 15px;
    }
  }
}

.ittl1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .ttl1 {
    font-size: 17px;
    font-family: 'bold';
  }

  .ttl2 {
    font-size: 13px;
    color: var(--ion-color-primary);
  }
}

.ittl2 {
  display: flex;
  flex-direction: row;
  align-items: center;

  .ttl1 {
    font-size: 18px;
    font-family: 'bold';
  }
}

ion-modal.custom_modal {
  border-radius: 20px 20px 0px 0px;
  top: 50vh;
  --box-color: #d7d8da;

  .modal-wrapper {
    width: 100% !important;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
}

/* Add this to your global styles or component-specific styles */
.max-width-container {
  max-width: 1100px; /* Adjust this value to your preferred max width */
  margin: 0 auto; /* Centers the container horizontally */
  padding: 0 16px; /* Optional: Adds some padding on the sides */
  box-sizing: border-box; /* Ensures padding is included in the total width */
}
